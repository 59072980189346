.wrapperMenu {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 5%;
  background-image: url('/static/images/noisy-texture.svg');
  opacity: 0;
  box-sizing: border-box;
  z-index: 3;
  transform-origin: left center;
}

@media (min-width: 600px) {
  .menu__item {
    &:last-of-type {
      .menu__item__text {
        margin-bottom: 0;
      }
    }
  }
}

.menu__item__text {
  position: relative;
  display: block;
  margin-bottom: 30px;
  padding: 10px 0;
  font-size: 50px;
  font-weight: 500;
  font-family: "made_mirageregular", serif;
  line-height: 0.95;
  color: color(blackLight);
  opacity: 0.3;
  transition: opacity 0.2s;

  &:after {
    content: "";
    position: absolute;
    display: block;
    width: 200px;
    height: 21px;
    padding-top: 10px;
    background-image: url('/static/images/line-menu-2.png');
    background-position: 0 center;
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0;
    transform: scale(0);
    transition: opacity 0.2s ease-out 0.4s,
    transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
    transform-origin: center left;
  }

  &:hover {
    opacity: 1;
    font-weight: 600;

    &:after {
      opacity: 1;
      transform: scale(1);
      transform-origin: center left;
      transition: opacity 0.2s ease-out 0s,
      transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
    }
  }
}

.menu__links {
  min-width: 50vh;
}

@media (max-height: 600px) {
  .menu__item__text {
    margin-bottom: 15px;
  }
}

@media (max-width: 1400px) and (min-width: 601px) {
  .menu__links {
    min-width: 35vh;
  }

  .menu__item__text {
    font-size: 35px;
  }
}

@media (max-width: 600px) {
  .menu__links {
    min-width: auto;
    width: 100%;
  }
  .menu__item__text {
    font-size: 30px;
    margin-bottom: 10px;
    opacity: 1;
  }
  .menu__item:first-child .menu__item__text {
    padding-top: 0;
  }
}

.menu__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

@media (max-width: 1200px) and (min-width: 601px) {
  .wrapperMenu {
    padding: 3%;
  }

  .menu__content {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .wrapperMenu {
    padding: 1rem;
    box-sizing: border-box;
    overflow-y: auto;
  }

  .menu__content {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.menu__img {
  height: 70vh;
  width: 55vw;
  opacity: 0;
  transform: scale(1, 1);
  object-fit: cover;
  object-position: top;
}

@media (max-width: 1200px) and (min-width: 601px) {
  .menu__img {
    max-width: 45vw;
    min-height: 45vh;
    height: auto;
  }
}

@media (max-width: 600px) {
  .menu__img {
    height: 30vh;
    width: 100%;
  }
}

.menu__topbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  :hover {
    cursor: pointer;
  }
}

.menu__close {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: none;
  border: none;

  :hover {
    cursor: pointer;
  }
}

.menu__close__img {
  width: 20px;
  height: 20px;
}

.menu__close__text {
  margin-left: 20px;
  font-weight: 500;
  font-family: "made_mirageregular", serif;
  color: color(blackLight);
  font-size: 20px;
  line-height: 130%;
}

.menu__extra {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}


.menu__extra__languages {

  span {
    width: auto;
    height: 20px;
    margin-right: 8px;
    cursor: pointer;

    &:hover {
      font-weight: 600;
    }

    &:last-child {
      margin-right: 0;
    }
  }

}

.menu__extra__legals, .menu__extra__newsletter {
  font-family: "made_mirageregular", serif;
  font-style: normal;
  font-size: 16px;
  line-height: 95%;
  color: color(blackLight);
  font-weight: 500;

  &:hover {
    font-weight: 600;
  }
}

.menu__extra__links {
  align-items: center;
  display: flex;
  gap: 30px;
}

.menu__extra__socials {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.menu__extra__socials__name {
  font-family: "made_mirageregular", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 200%;
  text-transform: uppercase;
  color: color(blackLight);
  opacity: 0.3;

  &:hover {
    opacity: 1;
    cursor: pointer;
  }
}

.menu__extra__socials__separator {
  margin-left: 20px;
  margin-right: 20px;
  opacity: 0.3;
  font-size: 30px;
  line-height: 1;
}

@media (max-width: 600px) {
  .menu__extra {
    width: 100%;
    margin-top: 20px;
    flex-wrap: wrap;
  }

  .menu__extra__links {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }

  .menu__extra__legals, .menu__extra__newsletter {
    font-size: 14px;
  }

  .menu__extra__socials__separator {
    margin-left: 15px;
    margin-right: 15px;
  }

  .menu__extra__socials__name {
    font-size: 14px;
    line-height: 100%;
  }
}
