.webgl {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
  width: 100vw;
  height: 100vh;
}

.lottie {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  z-index: -1;


  &__loader {
    z-index: 1;
    pointer-events: none;
  }

  @media (max-width: 768px) {
    top: 50%;
    height: 100vw;
    transform: translateY(-50%);
  }
}



