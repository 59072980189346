@mixin fontface($name, $file, $weight: normal, $style: normal) {
  @font-face {
    font-family: '#{$name}';
    src: url('/static/fonts/#{$file}.woff') format('woff'), url('/static/fonts/#{$file}.woff2') format('woff2');
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

@mixin menuBarPadding() {
  padding-top: 60px;
  
  @media (min-width: 601px) {
    padding-top: 80px;
  }
}
