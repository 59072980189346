.wrapp--sea-history {
  @include menuBarPadding();
}

.sea_history_image1__container {
  position: relative;
  width: 90vw;
  margin: 150px auto 120px;
  overflow: hidden;
}

.sea_history_image1__container:before{
  content: '';
  float: left;
  padding-top: 60%;
}

.sea_history_image1 {
  position: absolute;
  top: 10%;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 120%;
  object-fit: cover;
  object-position: 0 50%;
}

@media (max-width: 600px) {
  .sea_history_image1__container {
    height: 300px;
  }
}

.sea_history_image2__container {
  position: relative;
  width: 440px;
  margin: 100px auto 80px;
  overflow: hidden;
}

.sea_history_image2__container:before{
  content: '';
  float: left;
  padding-top: 156%;
}

.sea_history_image2 {
  position: absolute;
  top: 10%;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 120%;
  object-fit: cover;
}

@media (max-width: 600px) {
  .sea_history_image2__container {
    width: 80vw;
    height: 540px;
    margin-left: 10vw;
  }

  .sea_history_image2 {
    height: 850px;
  }
}

.sea_history_image3__container {
  position: relative;
  width: 80vw;
  max-width: 1040px;
  margin: 80px auto 60px;
  overflow: hidden;
}

.sea_history_image3__container:before{
  content: '';
  float: left;
  padding-top: 65%;
}

.sea_history_image3 {
  position: absolute;
  top: 0;
  left: -5%;
  width: 110%;
  height: auto;
  min-height: 150%;
  object-fit: cover;
}

@media (max-width: 600px) {
  .sea_history_image3__container {
    height: 300px;
  }
}

// .sea_history_image4__container {
//   width: 60vw;
//   margin-left: 20vw;
//   height: 480px;
//   margin-top: 80px;
//   margin-bottom: 60px;
//   overflow: hidden;
//   position: relative;
// }

// .sea_history_image4 {
//   height: 700px;
//   width: 100%;
//   position: absolute;
//   top: 0;
//   left: 0;
//   object-fit: cover;
// }


// @media (max-width: 600px) {
//   .sea_history_image4 {
//     height: 550px;
//     margin-left: 0;
//   }

//   .sea_history_image4__container {
//     height: 350px;
//     width: 80vw;
//   }

//   .sea_history_image4_mobile {
//     height: 700px;
//     width: 100%;
//     position: absolute;
//     top: 0;
//     left: 0;
//   }

//   .sea_history_image4_img{
//     width:100%;
//     height:160px;
//     margin-top:-5px;
//   }

//   .sea_history_image4_line1{
//     width:100%;
//     height:5px;
//     z-index:2;
//     position:absolute;
//     left:0;
//     top:120px;
//     background-color:white;
//   }

//   .sea_history_image4_line2{
//     width:100%;
//     height:5px;
//     z-index:2;
//     position:absolute;
//     left:0;
//     top:240px;
//     background-color:white;
//   }

//   .sea_history_image4_line3{
//     width:100%;
//     height:5px;
//     z-index:2;
//     position:absolute;
//     left:0;
//     top:360px;
//     background-color:white;
//   }
  
//   .sea_history_image4_mobile__container {
//     width: 80vw;
//     margin-left: 10vw;
//     height: 480px;
//     margin-top: 80px;
//     margin-bottom: 60px;
//     object-fit: cover;
//     overflow: hidden;
//     position: relative;
//   }

// }

// @media (max-width: 1200px) and (min-width: 601px) {
//   .sea_history_image4 {
//     width: 80vw;
//     height: 480px;
//     margin-top: 80px;
//     margin-bottom: 60px;
//     object-fit: cover;
//   }
// }