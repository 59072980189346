.button {
  cursor: pointer;

  &--icon {
    &:hover {
      > svg {
        transform: scale(1.2);
        opacity: 1;
      }
    }

    > svg {
      pointer-events: none;
      opacity: 0.4;
      transition: all 1s ease;
    }
  }

  &--icon-current {
    > svg {
      opacity: 1;
    }
  }

  &--reset{
    background-color: transparent;
    border: none;
  }
}
