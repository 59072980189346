.wrapperWhiteWine {
  display: flex;
  flex-direction: column;

  @include menuBarPadding();
}

.whiteWine__intro__strap {
  margin-left: calc(20vw + 20px);
  margin-top: 12vh;
  text-transform: uppercase;
  font-family: "made_mirageregular", serif;
  color: #2a323d;
  font-size: 18px;
  font-weight: 500;
  line-height: 200%;
}

.whiteWine__intro__title {
  margin-left: 20vw;
  margin-bottom: 15vh;
  font-family: "made_mirageregular", serif;
  color: #2a323d;
  font-size: 60px;
  font-weight: 400;
  line-height: 95%;
}

@media (max-width: 1200px) and (min-width: 601px) {
  .whiteWine__intro__title {
    margin-bottom: 5vh;
  }
}

@media (max-width: 600px) {
  .whiteWine__intro__strap {
    margin-left: calc(5vw + 20px);
  }

  .whiteWine__intro__title {
    margin-bottom: 3vh;
    margin-left: calc(5vw);
  }
}

.whiteWine__main__content {
  margin-top: -160px;
  margin-left: 18vw;
}

.whiteWine__main__label {
  opacity: 0.3;
  text-transform: uppercase;
  font-family: "made_mirageregular", serif;
  color: #2a323d;
  font-size: 18px;
  font-weight: 500;
  line-height: 200%;
}

.whiteWine__main__vintage {
  font-family: "made_mirageregular", serif;
  color: #2a323d;
  font-size: 220px;
  font-weight: 400;
  line-height: 0.95;
}

@media (max-width: 1200px) and (min-width: 601px) {
  .whiteWine__main__content {
    margin-top: -110px;
    margin-left: 5vw;
  }

  .whiteWine__main__vintage {
    font-size: 140px;
  }
}

@media (max-width: 600px) {
  .whiteWine__main__content {
    margin-top: -90px;
    margin-left: 5vw;
    display: flex;
    flex-direction: column-reverse;
  }

  .whiteWine__main__vintage {
    font-size: 90px;
  }
}

.whiteWine__presentation__title {
  max-width: 25vw;
  margin-left: 40vw;
  margin-top: 5vh;
  font-family: "made_mirageregular", serif;
  color: #2a323d;
  font-size: 30px;
  line-height: 100%;
}

.whiteWine__presentation__text {
  max-width: 25vw;
  margin-top: 5vh;
  margin-left: 40vw;
  line-height: 200%;
  font-size: 18px;
  font-weight: 300;
  font-family: monarcha, serif;
  
}

@media (max-width: 1400px) and (min-width: 601px) {
  .whiteWine__presentation__title {
    max-width: 50vw;
    margin-left: 25vw;
    margin-right: auto;
  }

  .whiteWine__presentation__text {
    max-width: 50vw;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 600px) {
  .whiteWine__presentation__title {
    max-width: 80vw;
    margin-left: 10vw;
    margin-right: auto;
    font-size: 24px;
  }

  .whiteWine__presentation__text {
    max-width: 80vw;
    font-size: 16px;
    margin-left: auto;
    margin-right: auto;
  }
}

.whiteWine__variety {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 20vh;
}

.whiteWine__variety__value_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
}

.whiteWine__variety__label {
  font-family: "made_mirageregular", serif;
  color: #2a323d;
  font-size: 30px;
  font-weight: 500;
  line-height: 100%;
  margin-top: -3px;
}

.whiteWine__variety__separator {
  margin-left: 20px;
  margin-right: 20px;
  width: 20px;
  height: 1px;
  background-color: #002130;
  opacity: 0.1;
}

.whiteWine__variety__value {
  font-style: italic;
  font-family: monarcha, serif;
  line-height: 115%;
  font-weight: 300;
  font-size: 24px;
  color: #2a323d;
}

@media (max-width: 600px) {
  .whiteWine__variety {
    flex-direction: column;
    margin-left: 10vw;
  }

  .whiteWine__variety__label {
    margin-bottom: 20px;
  }
}

.whiteWine__download {
  margin-top: 30vh;
  height: 620px;
  margin-left: 10vw;
  width: 80vw;
  position: relative;
  background-color: #f7f4f4;
}

.whiteWine__download__img {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}

.whiteWine__download__img_container {
  position: absolute;
  top: -40px;
  left: 40px;
  height: calc(100% - 40px);
  width: 30vw;
  overflow: hidden;
}

.whiteWine__download__content {
  margin-top: 8%;
  margin-left: 50%;
}

.whiteWine__download__text {
  max-width: 70%;
  font-family: "made_mirageregular", serif;
  color: #2a323d;
  font-size: 40px;
  font-weight: 500;
  line-height: 130%;
  margin-bottom: 50px;
}

.whiteWine__download__link {
  font-family: "made_mirageregular", serif;
  color: #2a323d;
  font-size: 24px;
  font-weight: 500;
  line-height: 100%;
}

.whiteWine__download__line {
  width: 260px;
  height: 1px;
  background-color: #2a323d;
  margin-top: 10px;
}

@media (max-width: 1200px) and (min-width: 601px) {
  .whiteWine__download {
    margin-top: 30vh;
    height: 520px;
    margin-left: 5vw;
    width: 90vw;
    position: relative;
    background-color: #f7f4f4;
  }

  .whiteWine__download__img_container {
    position: absolute;
    top: -40px;
    left: 40px;
    height: 480px;
    width: 30vw;
    overflow: hidden;
  }

  .whiteWine__download__content {
    margin-left: 50%;
    margin-top: 8%;
  }

  .whiteWine__download__text {
    font-size: 30px;
  }

  .whiteWine__download__link {
    font-size: 18px;
  }

  .whiteWine__download__line {
    width: 260px;
    height: 1px;
    background-color: #2a323d;
    margin-top: 10px;
  }
}

@media (max-width: 600px) {
  .whiteWine__download {
    margin-top: 20vh;
    height: 720px;
    margin-left: 0;
    width: 100vw;
    position: relative;
    background-color: #f7f4f4;
  }

  .whiteWine__download__img_container {
    position: relative;
    top: -40px;
    left: 0px;
    height: 400px;
    width: 80vw;
    overflow: hidden;
  }

  .whiteWine__download__content {
    margin-left: 10%;
    margin-top: -30px;
  }

  .whiteWine__download__text {
    max-width: 80%;
    font-size: 24px;
  }

  .whiteWine__download__link {
    font-size: 18px;
  }

  .whiteWine__download__line {
    width: 260px;
    height: 1px;
    background-color: #2a323d;
    margin-top: 10px;
  }
}
