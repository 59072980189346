.common_navbar {
    width: 100vw;
    background-color: white;
    height: 80px;
    border-bottom: 1px solid color("blueLight");
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 10vw;
    padding-right: 10vw;
    box-sizing: border-box;
    margin-bottom: 50px;
    position: fixed;
    top: 0;
    left: 0;
    transform: translateY(-100%);
    transition: all 0.5s;
    z-index: 1;
  
    &.home_navbar {
      pointer-events: none;
      background-color: transparent;
      border-bottom: none;
      align-items: center;
      justify-content: space-between;
      padding-right: 2.4vw;
  
      .common_navbar_link {
        pointer-events: auto;
        color: white;
      }
    }
  
    @media (max-width: 600px) {
      height: 60px;
    }
    &,
    &.home_navbar {
      padding: 0 7vw;
    }
  }
  
.common_navbar_hamburger {
    display: none;
  
    img {
      width: 32px;
      height: 29px;
    }
}
  
.hamburger_navbar {
    justify-content: space-between;
  
    .home_navbar_wave {
      filter: invert(1);
      position: relative;
    }
}
  
@media (max-width: 600px) {
    .hamburger_navbar {
      pointer-events: none;
      background-color: transparent;
      border-bottom: none;
      padding-left: 18px;
      padding-right: 68px;
      margin-bottom: 0;
      padding-top: 33px;
      width: 100%;
  
      .common_navbar_hamburger {
        display: block;
        pointer-events: auto;
      }
      .common_navbar_link,
      .common_navbar_logo,
      .home_navbar_pass {
        display: none;
      }
    }
}
  
.home_navbar_right {
    opacity: 0;
    pointer-events: auto;
    display: flex;
    align-items: center;
  
    .home_navbar &,
    .hamburger_navbar & {
      opacity: 1;
    }

    .classic_navbar &{
      display: none;
    }
}
  
.byPassAnimation {
    opacity: 0;
    font-family: "made_mirageregular", serif;
    font-size: 1rem;
    line-height: 1.3;
    color: #fff;
    margin-right: 26px;
}
  
.home_navbar_wave {
    display: block;
    width: 41px;
    cursor: pointer;
    svg {
      width: 41px;
      height: 10px;
    }
}
  
.common_open {
    transform: translateY(0%);
}
  
.common_navbar_link {
    font-family: "made_mirageregular", serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 130%;
    text-align: center;
    color: color("mainDark");
    &:hover {
      cursor: pointer;
      font-weight: 600;
    }
}
  
.common_navbar_logo {
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    top: 24px;
    left: calc(50vw - 60px);
  
    .home_navbar & {
      display: none;
    }
  
    @media (max-width: 600px) {
      top: 17px;
    }
}