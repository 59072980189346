
.history{
    font-family: monarcha, serif;
    font-weight: 300;
    font-size: 1.125rem;
    line-height: 2;
    color: color("mainDark");

    &__hero{
      width: 90vw;
      height: 580px;
      margin-left: 5vw;
      margin-top: 5vh;
      position: relative;
      overflow: hidden;
  
      @media (max-width: 600px) {
        height: 450px;
      }
    }

    &__hero-img{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 0 80%;
        transform: scale(1.2) translateY(0);

        img {
            transform: none;
        }

        &--earth{
            object-position: 0 28%;
        }

        &--sea{
            object-position: 0 60%;
        }
    }

    &__title{
        margin-top: 20px;
        margin-left: 10vw;
        font-family: "made_mirage", serif;
        font-weight: 400;
        font-size: 5rem;
        line-height: 1;
        opacity: 1;

        &--sea{
            width: 33vw;
            max-width: 500px;
        }

        &--earth{
            width: 60vw;
            max-width: 950px;
        }

        &--wine{
            width: 50vw;
            max-width: 800px;
        }

        @media (max-width: 1200px) and (min-width: 601px) {
            &--sea{
              width: 50vw;
            }

            &--earth{
                width: 80vw;
            }
        }
          
        @media (max-width: 600px) {
            margin-left: 5vw;
            font-size: 3.125rem;

            &--sea{
                width: 70vw;
            }

            &--earth{
                width: 90vw;
            }

            &--wine{
                width: 80vw;
            }
        }
    }

    &__desc{
        font-family: "made_mirage", serif;
        font-weight: 400;
        font-size: 4rem;
        line-height: 1.6;
        opacity: 1;

        &--sea{
            width: 50vw;
            margin-top: 150px;
            margin-left: 25vw;
            font-size: 3rem;
        }

        &--earth{
            width: 70vw;
            margin-top: 100px;
            margin-bottom: 100px;
            margin-left: 15vw;
            text-align: center;
        }

        &--wine{
            width: 70vw;
            margin-top: 200px;
            margin-bottom: 200px;
            margin-left: 15vw;
            text-align: center;
        }

        @media (max-width: 1200px) and (min-width: 601px) {
            &--sea {
                width: 60vw;
                margin-left: 20vw;
                font-size: 2.5rem;
            }
            &--earth{
                width: 80vw;
                margin-top: 150px;
                margin-left: 10vw;
                font-size: 3.125rem;
            }
            &--wine{
                width: 80vw;
                margin-top: 150px;
                margin-left: 10vw;
                font-size: 3.125rem;
            }
          }
          
          @media (max-width: 600px) {
            &--sea {
                width: 80vw;
                margin-top: 100px;
                margin-left: 10vw;
                font-size: 1.875rem;
                text-align: center;
            }
            &--earth{
                width: 80vw;
                margin-top: 50px;
                margin-bottom: 120px;
                margin-left: 10vw;
                font-size: 1.875rem;
            }
            &--wine{
                width: 80vw;
                margin-top: 100px;
                margin-left: 10vw;
                font-size: 2.1875rem;
            }
          }
    }

    &__content-title{
        font-family: "made_mirage", serif;
        font-weight: 400;
        font-size: 2.5rem;
        line-height: 1.4;
        margin-left: 30vw;
        margin-bottom: 80px;

        &--earth{
            width: 40vw;
            margin-left: 20vw;
            margin-bottom: 40px;
        }

        &--wine{
            width: 35vw;
            margin-left: 30vw;
            margin-bottom: 40px;
        }

        &--left{
            margin-left: 10vw;
        }

        &--reset{
            margin-left: 0;
        }
          
        @media (max-width: 600px) {
            max-width: 70vw;
            margin-bottom: 20px;
            margin-left: 10vw;
            font-size: 1.875rem;

            &--earth{
                width: 70vw;
                margin-left: 10vw;
                font-size: 2.1875rem;
            }

            &--wine{
                width: 60vw;
                margin-bottom: 20px;
            }

            &--reset{
                width: 100%;
                margin-left: 0;
            }
        }
    }

    &__content-text{
        max-width: 440px;
        margin-top: 40px;
        margin-left: auto;
        margin-right: auto;

        &--left{
            margin-left: 20vw;
        }

        &--reset{
            margin-left: 0;
        }

        @media (max-width: 1200px) {
            max-width: 75%;
            margin-left: 10vw;

            &--reset{
                max-width: 90%;
                margin-left: 0;
            }
        }
    }

    &__triptych{
        position: relative;
        width: 80vw;
        max-width: 1040px;
        height: 600px;
        margin: 80px auto 60px;
        overflow: hidden;

        &:before,
        &:after{
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            width: 16px;
            height: 110%;
            background-color: white;
            z-index: 1;
            transform: translate3d(-50%,-50%,0);
        }

        &:before{
            left: 25%;
        }
          
        &:after{
            left: 75%;
        }

        @media (max-width: 600px) {
            height: 500px;

            &:before,
            &:after{
                left: 50%;
                width: 100%;
                height: 10px;
                transform: translate3d(-50%,-50%,0);
            }

            &:before{
                top: 25%;
            }

            &:after{
                top: 75%;
            }
        }
    }

    &__triptych-img{
        position: absolute;
        top: 5%;
        left: 0;
        width: 100%;
        height: 100%;
        // height: 900px;
        min-height: 130%;
        object-fit: cover;

        @media (max-width: 600px) {
        }
    }

    &__triptych-mask{
        position: absolute;
        top: 50%;
        left: 50%;
        width: 16px;
        height: 110%;
        background-color: white;
        z-index: 1;
        transform: translate3d(-50%,-50%,0);

        @media (max-width: 600px) {
            width: 100%;
            height: 10px;
        }
    }

    &__next{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        width: 100%;
        max-width: 800px;
        // margin-top: 15vh;
        margin-top: 380px;
        margin-left: auto;
        margin-right: auto;   

        @media (max-width: 600px) {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 1fr 1fr;
            grid-gap: 50px;
            width: 100vw;
            margin-top: 140px;
            margin-left: 0vw;
        }
    }

    &__next-link{
        @media (max-width: 600px) {
            display: flex;
            flex-direction: column-reverse;
        }
    }

    &__next-img{
        width: 100%;
        height: 620px;
        object-fit: cover;

        @media (max-width: 600px) { 
            height: 200px;
        }
    }

    &__next-title{
        max-width: 65%;
        margin-bottom: -110px;
        margin-left: 15px;
        font-family: "made_mirage", serif;
        font-weight: 400;
        font-size: 2.5rem;
        line-height: 0.95;
        opacity: 0;

        &--top{
        }

        &--bottom{
            margin-bottom: 0;
            margin-top: -110px;
        }

        @media (max-width: 600px) { 
            font-size: 2rem;
            margin-bottom: 0px;
            margin-left: 115px;
            margin-top: -5px;
          
            &--bottom{
              margin-top: -5px;
              margin-left: auto;
              margin-right: 100px;
            }
        }
    }
}