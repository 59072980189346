.panel {
  display: flex;
  width: 100vw;
  height: 100%;
  padding: spacing(10);
  box-sizing: border-box;

  &--hero {
    align-items: center;
    padding-left: 200px;
    //width: calc(100vw - 200px);
  }

  &--reverse {
    flex-direction: row-reverse;
  }

  &--text {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &--half {
    justify-content: space-between;
  }

  &--halfwithtext {
    padding-left: 140px; /* aside width */
  }

  &--columns {
    padding-top: 0;
    padding-bottom: 0;
  }

  &--pretranslation {
    padding-left: 220px; /* aside width + margin */
  }

  &--fullwithtranslation {
  }

  &--twothirds {
    justify-content: space-between;
  }

  &--triptych {
  }

  &--polyptych {
  }

  &__direction{
   align-self: flex-end;
   margin-left: auto;

   > svg{
     width: 20px;
     transform: rotate(90deg);
   }
  }

  &__container {
    width: 100%;
    box-sizing: border-box;

    .panel--columns & {
      display: flex;
      justify-content: space-between;
      padding-left: 70px;
    }
  }

  &__inner {
    position: relative;
    overflow: hidden;

    .panel--full & {
      width: 100%;
    }

    .panel--half & {
      flex: 1;

      &:first-of-type {
        margin-right: spacing(10);
      }
    }

    .panel--halfwithtext & {
      flex: 1 0 calc(50% - 40px);

      &--image {
        flex: 1 0 50%;
      }

      &--text {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: spacing(10);
      }
    }

    .panel--halfwithtext.panel--reverse & {
      &:first-of-type {
        margin-right: 0;
      }

      &--image {
        flex: 1 0 calc(50% + 20px);
        margin-right: 20px;
      }
    }

    .panel--columns & {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin: 0 spacing(10);
    }

    .panel--twothirds & {
      &:first-of-type {
        flex: 1;
        margin-right: spacing(10);
      }

      &:last-of-type {
        flex: 2;
      }
    }

    .panel--triptychwithtext & {
      &:first-of-type {
        flex: 1 0 calc(55%);
      }

      &:last-of-type {
        flex: 1 0 calc(45%);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: spacing(10);
      }
    }

    .panel--polyptych & {
      flex: 1;

      &:not(:last-of-type) {
        margin-right: spacing(10);
      }
    }
  }

  &__title {
    &--columns {
      max-width: 385px;
      margin: 1.5625em 30px;
    }
  }

  &__img {
    position: relative;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);

    &--top {
      // position: absolute;
      top: 0;
      bottom: 50%;
      left: 50%;
      height: 50%;
      transform: translate(-50%, -40px);
      overflow: hidden;

      > img{
      }
    }

    &--bottom {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 50%;
      transform: translate(-50%, 40px);
      overflow: hidden;

      > img{
      }
    }

    &--top,
    &--bottom {
      & > img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: translate(-50%,-50%);
      }
    }
  }

  &__svg {
    width: 100%;
    height: 100%;
  }

  &__filter {
    position: relative;
    width: 100%;
    height: 100%;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 27%;
      width: 40px;
      height: 100%;
      background-color: #fff;
    }
  }

  &__content {
    .panel--text & {
      max-width: 770px;
      text-align: center;
    }

    .panel--pretranslation & {
      display: flex;
      align-items: center;
      max-width: 520px;
    }

    .panel--halfwithtext & {
      max-width: 360px;
      margin: spacing(20) auto;
    }

    .panel--triptychwithtext & {
      max-width: 360px;
      margin: spacing(20) auto;
    }

    @media (max-width: 1400px){
      .panel--text & {
        max-width: 65%;
      }
    }

    @media (max-width: 1400px) and (min-width: 681px) {
      .panel--pretranslation & {
        max-width: 55%;
      }

      .panel--halfwithtext & {
        max-width: 90%;
        margin: spacing(12) auto;
      }

      .panel--triptychwithtext & {
        max-width: 90%;
        margin: spacing(12) auto;
      }

      .panel--triptychwithtext & {
        max-width: 90%;
        margin: spacing(12) auto;
      }
    }
  }

  &__desc {
    flex: 0 0 50%;
    max-width: 560px;
  }

  &__illu {
    flex: 1;
  }

  &__translate {
    width: 100%;
    height: 100%;
    transform: translateX(-40%);
  }

  &__containerMask {
    width: 100%;
    height: 100%;
    transform: translateX(-40%);
  }

  // Ratio landscape +
  @media (min-aspect-ratio: 16 / 9) and (min-width: 1200px){
    &__content{
      .panel--halfwithtext & {
        max-width: 90%;
        margin: spacing(12) auto;
      }
    }
  }

  // Ratio landscape ++
  @media (min-aspect-ratio: 16 / 9) and (max-width: 1200px){
    &__desc{
      flex: 0 0 70%;
    }
    
    &__inner{
      .panel--halfwithtext & {
        &--image {
          flex: 1 0 30%;
        }
      }
  
      .panel--halfwithtext.panel--reverse & {
        &--image {
          flex: 1 0 calc(30% + 20px);
        }
      }
    }
  }

  @media (min-aspect-ratio: 16 / 9) and (max-width: 980px){
    &__inner{
      .panel--halfwithtext & {
        &--image {
          flex: 1 0 10%;
        }
      }
  
      .panel--halfwithtext.panel--reverse & {
        &--image {
          flex: 1 0 calc(10% + 20px);
        }
      }
    }

    &__desc{
      flex: 0 0 70%;
    }
  }

  // MOBILE
  @media (max-width: 680px) {
    padding: spacing(4);

    &--hero {
      padding-top: spacing(8);
      padding-left: spacing(4);
      padding-right: spacing(12);
    }

    &--pretranslation {
      padding-left: spacing(4);
    }

    &--half {
      flex-direction: column;
    }

    &--halfwithtext {
      flex-direction: column;
      padding-top: spacing(8);
    }

    &--columns {
      flex-direction: column-reverse;
      padding-left: spacing(4);

      &.panel--reverse {
        flex-direction: column;
        padding-top: spacing(8);

        .panel__title--columns {
          margin-top: 0;
          margin-bottom: 1em;
        }
      }

      &:not(.panel--reverse) {
        .panel__title--columns {
          margin-top: 1em;
          margin-bottom: 0;
        }
      }
    }

    &--triptychwithtext {
      flex-direction: column;
    }

    &--polyptych {
      flex-direction: column;
    }

    &__container {
      .panel--columns & {
        min-height: 50%;
        height: 100%;
        padding-left: 0;
      }
    }

    &__inner {
      .panel--half & {
        &:first-of-type {
          margin-right: 0;
          margin-bottom: spacing(4);
        }
      }

      .panel--halfwithtext & {
        flex: auto;

        &--image {
          flex: 2 1 auto;
        }

        &--text {
          flex: 2 0 auto;
          display: block;
          margin-right: 0;
        }
      }

      .panel--halfwithtext.panel--reverse & {
        &--image {
          flex: 2 1 auto;
        }
      }

      .panel--columns & {
        margin: 0;

        &:first-of-type {
          margin-right: spacing(4);
        }
      }

      .panel--triptychwithtext & {
        margin: 0;

        // Image filter
        &:first-of-type {
          flex: 2 1 auto;
          margin-bottom: spacing(4);
        }

        &:last-of-type {
          flex: 2 0 auto;
        }
      }

      .panel--polyptych & {
        &:not(:last-of-type) {
          margin-right: 0;
          margin-bottom: spacing(4);
        }
      }
    }

    &__content {
      .panel--text & {
        max-width: none;
        text-align: left;
      }

      .panel--halfwithtext & {
        max-width: none;
        margin: 0 auto spacing(4);
      }

      .panel--triptychwithtext & {
        max-width: none;
        margin: 0;
      }

      .panel--pretranslation & {
        max-width: 100%;
      }
    }

    &__desc {
      flex: 0 0 100%;
    }

    &__img {
      max-height: 100%;

      &--top {
        transform: translate(-50%, -8px);
      }

      &--bottom {
        transform: translate(-50%, 8px);
      }
    }

    &__filter {
      &:after {
        display: none;
      }
    }
  }

  &__containerMask {
    transform: translateX(-32px);
  }
}
