.mobile-landscape{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100vw;
    padding: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: color(waterDark);
    box-sizing: border-box;
    z-index: 100;

    &__illu{
        max-width: 100px;
    }
    &__desc{
        text-align: center;
        color: white;
    }
}