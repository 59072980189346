.firefox-alert {
  &__container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: color(waterDark);
    z-index: 50;
  }

  &__modal {
    display: none;
    max-width: 80%;
    padding: 6%;
    text-align: center;
    color: white;
    background-color: color(waterDark);
    border-radius: 10px;
    box-shadow: 1px 1px 25px #313e4c;
  }

  &__desc {
    margin-bottom: 32px;
    font-size: 1.125rem;
    color: white;
  }

  &__choices {
    // Redirect to Chrome
    a {
      display: inline-block;
      padding: 10px 18px;
      color: color(waterDark);
      background-color: white;
      border-radius: 10px;

      &:hover {
        background-color: rgba(255, 255, 255, 0.9);
      }
    }

    // Continue to Firefox
    button {
      height: 37px;
      padding: 10px 18px;
      font-size: 1.125rem;
      font-family: 'made_mirageregular', serif;
      color: white;
      background-color: color(waterDark);
      border: none;
      box-sizing: border-box;
      outline: none;
      cursor: pointer;

      &:first-child {
        margin-right: 24px;
      }

      &:hover {
        span {
          border-bottom: 1px solid white;
        }
      }
    }
  }
}
