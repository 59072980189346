.nav-home {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 50px 0 0;
  opacity: 0;
  transform: opacity 0.4s ease;
  pointer-events: none;
  z-index: 10;

  &--active {
    opacity: 1;
    pointer-events: auto;
  }

  svg {
    stroke: white;

    circle {
      stroke: transparent;
    }
  }

  &__button {
    position: relative;

    &:not(:last-of-type) {
      margin-right: 60px;
    }

    // &::after {
    //   content: url('../../static/cross.svg');
    //   position: absolute;
    //   top: 100%;
    //   left: 50%;
    //   margin-top: 30px;
    //   opacity: 0;
    //   transform: translateX(-50%);
    // }

    &:hover {
      &::after {
        opacity: 1;
      }
    }

    &--current {
      &::after {
        opacity: 1;
      }

      svg {
        opacity: 1;
      }
    }

    @media (max-width: 680px) {
      &:not(:last-of-type) {
        margin-right: 5px;
      }

      &::after {
        margin-top: 0px;
      }
    }
  }
}

/* ELEMENT */
.nav-element {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 150px;
  opacity: 0;
  z-index: 10;

  &__pointer {
    position: absolute;
    line-height: 0;
    background-color: #fff;
    border: 0;
    border-radius: 100%;
    opacity: 0;
    pointer-events: auto;
    cursor: pointer;

    &:hover {
      .nav-element__pointer-inset {
        transform: scale(1.2);
      }
    }
  }

  &__pointer-outline {
    fill: color(waterDark);
  }

  &__pointer-inset {
    transform-origin: center;
    transition: all 0.6s ease;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__item {
    padding: 5px 5px 0px;
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 100%;

    &:not(:last-of-type){
      margin-bottom: spacing(4);
    }

    &:hover {
      &:not(.nav-element__item--current) {
        > svg {
          transform: scale(1.2);
          opacity: 1;
        }
      }
    }

    > svg {
      pointer-events: none;
      transition: all 1s ease;
    }

    &--current {
      pointer-events: none;
      > svg {
        fill: color(grey);

        circle {
          stroke: transparent;
        }
      }
    }
  }

  @media (max-width: 680px) {
    top: 8px;
    left: auto;
    right: 0;
    width: 100vw;
    height: auto;
    pointer-events: none;

    &__pointer {
      top: 21px;
      right: 26px;
    }

    &__content {
      flex-direction: row;
      align-items: flex-start;
      background-color: white;
      opacity: 0;
      pointer-events: auto;
      visibility: hidden;
      width: 100%;
      padding: 16px 0 8px;
    }

    &__item {
      margin-left: spacing(2);
      margin-right: spacing(2);
      margin-bottom: 0;
    }
  }
}
