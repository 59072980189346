@each $color-name, $color-value in colors() {
  .has-#{ $color-name }-color {
    color: #{$color-value};
  }

  .has-#{ $color-name }-background-color {
    background-color: #{$color-value};
  }
}

.hidden {
  &--desktop {
  }

  &--mobile {
  }

  @media (min-width: 680px) {
    &--desktop {
      display: none;
      visibility: hidden;
    }
  }

  @media (max-width: 680px) {
    &--mobile {
      display: none;
      visibility: hidden;
    }
  }
}

.split {
  /* display: block; */
  overflow: hidden;
}

.split:nth-child(1) {
  transform: translate3d(0.5ch, -0.5ch, 0);
}

.split:nth-child(2) {
  transform: translate3d(0.5ch, -0.5ch, 0);
}

.split__text {
  display: block;
  color: #ffffff;
}

.js-splited-text {
  opacity: 0;
  transform: translateY(0.5ch);
}

.select-none {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.title {
  &--huge {
    font-size: 6.25rem;
    font-family: "made_mirage", serif;
    font-weight: normal;
    line-height: 0.95;
    margin-bottom: 0.32em;
  }
  &--large {
    font-size: 4rem;
    font-family: "made_mirage", serif;
    font-weight: normal;
    line-height: 0.95;
    margin-bottom: 0.5em;
  }
  &--base {
    font-size: 2.5rem;
    font-family: "made_mirage", serif;
    font-weight: normal;
    line-height: 0.95;
    margin-bottom: 1.25em;
  }

  @media (max-width: 1400px) and (min-width: 681px) {
    &--huge {
      font-size: 4.5rem;
      font-family: "made_mirage", serif;
      font-weight: normal;
      line-height: 0.95;
      margin-bottom: 0.32em;
    }
    &--large {
      font-size: 3rem;
      font-family: "made_mirage", serif;
      font-weight: normal;
      line-height: 0.95;
      margin-bottom: 0.5em;
    }
    &--base {
      font-size: 2rem;
      font-family: "made_mirage", serif;
      font-weight: normal;
      line-height: 0.95;
      margin-bottom: 1.25em;
    }
  }

  @media (min-aspect-ratio: 16 / 9) and (max-width: 1200px){
    &--huge{
      font-size: 2.75rem;
    }
    &--large {
      font-size: 2rem;
    }
    &--base {
      font-size: 1.5rem;
    }
  }

  @media (max-width: 680px) {
    &--huge{
      font-size: 2.75rem;
    }
    &--large {
      font-size: 2rem;
    }
    &--base {
      font-size: 1.5rem;
    }
  }
}

.text {
  &--small {
    font-size: 1.125rem;
  }
}

@media (max-width: 1400px) and (min-width: 681px) {
  .text {
    &--small {
      font-size: 1rem;
    }
  }
}
