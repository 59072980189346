.video-outer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 20px solid white;
  box-sizing: border-box;
  pointer-events: none;
  z-index: 10;
  opacity: 0;
}

.institutional-video {
  position: relative;
  width: 100vw;
  //width: calc(1.78 * 100vh);
  min-width: 100vw;
  height: calc(100vh - 20px);
  overflow: hidden;

  &__container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__aside {
    position: absolute;
    top: 0;
    width: 22%;
    height: 100%;
    overflow: hidden;

    &--left {
      left: 12%;
    }

    &--right {
      right: 12%;
    }
  }

  &__illu {
    min-width: 100%;
    height: 50%;
    object-fit: cover;

    &:not(:last-of-type) {
      margin-bottom: 30px;
    }

    &--bottom{
      object-position: bottom;
    }
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: cursor;
    opacity: 0;
    z-index: 100;
  }

  &__content-container{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__layer-top {
    text-align: center;
    color: #fff;
  }

  &__title {
    margin-bottom: 50px;
  }

  &__player {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  &__placeholder {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    object-fit: cover;
    clip-path: polygon(
      36% 0%,
      64% 0%,
      64% 100%,
      36% 100%
    ); // topLeft, topRight, bottomRight, bottomLeft
  }

  &__theone {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    opacity: 0;
    pointer-events: none;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    iframe {
      box-sizing: border-box;
      width: 177.77777778vh;
      height: 56.25vw;
      min-width: 100%;
      min-height: 100%;
      position: absolute;
      pointer-events: none;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__close{
    position: absolute;
    top: 40px;
    right: 40px;
    z-index: 1;

    > svg{
      width: 20px;
      fill: white;
    }
  }
}
