.wrapp--wine-history {
  @include menuBarPadding();
}

.wine_history_image1__container {
  position: relative;
  width: 80vw;
  max-width: 600px;
  height: auto;
  margin-top: 140px;
  margin-bottom: 140px;
  margin-left: auto;
  margin-right: auto;
  object-fit: cover;
  overflow: hidden;
}

.wine_history_image1__container:before{
  content: '';
  float: left;
  padding-top: 130%;
}

.wine_history_image1 {
  position: absolute;
  top: -5%;
  left: -5%;
  width: 110%;
  height: auto;
  min-height: 100%;
  object-fit: cover;
}

@media (max-width: 1200px){
  .wine_history_image1__container {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}

@media (max-width: 600px){
  .wine_history_image1__container:before{
    padding-top: 110%;
  }
}

.wine_history_group_images {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80vw;
  margin-left: 10vw;
}

.wine_history_group_images_left {
  width: calc(65% - 10px);
  margin-right: 10px;
  display: flex;
  flex-direction: column;
}

.wine_history_group_images_right {
  width: 35%;
}

.wine_history_group_images_topleft {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.wine_history_text {
  font-family: monarcha, serif;
  font-size: 18px;
  line-height: 200%;
  color: #2a323d;
  margin-top: 40px;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  font-weight: 300;
}

.wine_history_text_left {
  margin-left: 20vw;
}

@media (max-width: 1200px) and (min-width: 601px) {
  .wine_history_text {
    max-width: 60%;
  }
}

@media (max-width: 600px) {
  .wine_history_text {
    margin-left: 10vw;
    max-width: 75%;
  }
}

.wine_history_image2 {
  height: 1000px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-position: 50%;
  background-size: cover;
}

.wine_history_image2__container {
  width: 80vw;
  margin-left: 10vw;
  height: 680px;
  margin-top: 120px;
  margin-bottom: 80px;
  object-fit: cover;
  overflow: hidden;
  position: relative;
}

@media (max-width: 1200px) and (min-width: 601px) {
  .wine_history_image2 {
    height: 900px;
  }

  .wine_history_image2__container {
    height: 540px;
  }
}

@media (max-width: 600px) {
  .wine_history_image2 {
    height: 750px;
  }

  .wine_history_image2__container {
    height: 450px;
  }
}

.wine_history_text_group {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 80vw;
  margin-left: 10vw;
  margin-bottom: 10vh;

  > p {
    max-width: 40%;
    margin-top: 0px;
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 600px) {
  .wine_history_text_group {
    display: flex;
    flex-direction: column;

    > div{
      &:first-of-type{
        margin-bottom: 40px;
      }
    }

    p {
      max-width: 100%;
    }
  }
}
