.word {
  display: inline-block;
}

.common_container {
  @include menuBarPadding();
}

.common{
  &__top{
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 10;

    > svg{
      width: 20px;
    }
  }

  &__section{
    &--legacy{
      margin-bottom: 30vw;
    }
    &--wines{
      margin-bottom: 21vw;
    }
  }

  &__columns{
    display: flex;
    max-width: 980px;
    margin: 0 auto 8vw;
    padding: 0 5vw;
  }

  &__column{
    display: flex;
    flex-direction: column;

    &:first-of-type{
      margin-right: 9.8%;
    }

    > p{
      margin-top: auto;
      margin-bottom: 0;
    }
  }

  &__title{
    color: color("mainDark");

    &--main{
      width: 30vw;
      margin-top: 12vh;
      margin-left: 20vw;
    }

    &--section{
      width: 40vw;
      margin: 20vh auto 10vh;
      text-align: center;
      
      &-spacing{
        margin-top: 28vw;
        margin-bottom: 20vw;
      }
    }

    &--history{
      width: 55vw;
      margin-top: 20vh;
      margin-left: 10vw;
      margin-bottom: 20vh;
    }
  }

  // &__title-sm{
  //   font-size: 2.5rem;
  //   line-height: 1.4;
  //   margin-bottom: 1em;
  // }

  &__container-text{
    max-width: 440px;
    margin-left: auto;
    margin-right: auto;

    &--large{
      max-width: 480px;
    }
  }

  &__text{
    margin-bottom: 40px;

    &--featured{
      width: 30vw;
      margin: -5vw 15vw 5vh auto;
      font-size: 1.5rem;
    }
  }

  &__grid-imgs{
    display: block;
    width: 80vw;
    margin: 100px auto 10vw;
  }

  &__wines{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80vw;
    margin: 0 auto;
  }

  &__wine{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40%;
    margin: 0 auto;

    &-brand{
      margin-left: 40px;
      text-transform: uppercase;
      font-family: "made_mirageregular", serif;
    }

    &-type{
      margin-left: 20px;
      margin-bottom: 10px;
      font-size: 4.375rem;
      font-family: "made_mirageregular", serif;
      line-height: 0.95;
    }

    &-separator{
      width: 80px;
      height: 1px;
      margin-bottom: 40px;
      background-color: color("mainDark");

      &--mobile{
        display: none;
        margin: 10px auto 0;
      }
    }

    &-img{
      width: 66%;
      max-width: 400px;
      height: 480px;
      object-fit: cover;
      object-position: bottom;
      img {
        width: 100%;
      }
    }

    &-label{
      margin: 30px auto 0;
      font-size: 1.5rem;
      font-family: "made_mirageregular", serif;
    }
  }

  @media (max-width: 1400px){
    &__title{
      &--section{
        &:not(.common__title--section-spacing) {
          margin-bottom: 5vh;
        }
      }
    }

    &__wines{
      width: 100%;
    }

    &__wine{
      width: 50%;
    }
  }

  @media (max-width: 1400px) and (min-width: 601px) {
    &__text {
      &--featured{
        width: 50vw;
        margin-top: -4vw;
        margin-right: 10vw;
      }
    }
  }

  @media (max-width: 600px) {
    &__columns{
      flex-wrap: wrap;
      margin-left: 10vw;
      margin-right: 10vw;
      padding: 0;
    }
    &__column{
      &:first-of-type{
        margin-right: 0;
        margin-bottom: 50px;
      }
    }
    &__title{
      &--main{
        width: 80vw;
        margin-top: 10vh;
        margin-left: 10vw;
      }
      &--section{
        width: 80vw;
        text-align: left;
      }
    }
    // &__title-sm{
    //   line-height: 1.2;
    //   margin-bottom: 0.6em;
    // }
    &__container-text{
      padding-left: 10vw;
      padding-right: 10vw;
    }
    &__text{
      line-height: 1.8;

      &--featured{
        width: 80vw;
        margin-top: 30px;
        margin-left: 10vw;
        margin-right: 10vw;
        font-size: 1.25rem;
      }
    }
    &__wines{
      flex-direction: column;
      width: 90%;
    }
  
    &__wine{
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      margin-top: 80px;
  
      &-type{
        font-size: 3.125rem;
      }
  
      &-separator{
        width: 80px;
        height: 1px;
        margin-bottom: 40px;
  
        &--mobile{
          display: block;
        }
      }
  
      &-img{
        width: 100%;
        max-width: none;
        height: 450px;
      }
    }
  }
}


// --------- Styles below could be refactored

.common_container {
  display: flex;
  flex-direction: column;
  margin-bottom: 10vh;
}

.common_next {
  margin-top: 80vh;
  height: 100vh;
  width: 100vw;
}

.common_history {
  position: relative;
}

@media (max-width: 600px) {
  .common__container-text {
    width: 80vw;
    text-align: left;
  }
}

.common_photobloc {
  padding: 0 7.5vw;

  > div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
  }

  &_image {
    width: calc(50% - 25px);
    min-height: 200px;
    object-fit: cover;
  }

  &_video {
    width: 100%;
    height: auto;
    margin-bottom: 50px;
  }
}

@media (max-width: 1365px) {
  .common_photobloc {

    > div {
      margin-bottom: 10px;
    }
    &_wrapper {
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
      overflow: hidden;
      max-width: 100%;
      margin-bottom: 10px;
    }
    &_image__container{
      display: flex;

      & > div{
        position: relative;
        width: 50%;
        overflow: hidden;

        &:first-of-type{
          margin-right: 10px;
        }

        &:before{
          content: '';
          float: left;
          padding-top: 100%;
        }
      }
    }
    &_image {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: translate(-50%,-50%);
    }
    &_video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.common_history_images {
  margin-left: 15vw;
  width: 70vw;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  padding: 40px 0;
  overflow: hidden;
}

@media (max-width: 1400px) and (min-width: 601px) {
  .common_history_images {
    width: 90vw;
    margin-left: 5vw;
  }
}

.common_history_img {
  width: 100%;
  height: 620px;
  object-fit: cover;
}

.common_history_next_text {
  font-family: "made_mirageregular", serif;
  color: color("mainDark");
  font-size: 2.5rem;
  line-height: 95%;
  font-weight: 400;
  max-width: 65%;
  margin-bottom: -110px;
  margin-left: 15px;
  opacity: 0;
}

.common_history_next_text_down {
  margin-bottom: 0;
  margin-top: -110px;
}

@media (max-width: 600px) {
  .common_history_images {
    margin-left: 0vw;
    width: 100vw;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-gap: 50px;
  }

  .common_history_image_container {
    display: flex;
    flex-direction: column-reverse;
  }

  .common_history_img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }

  .common_history_next_text {
    font-family: "made_mirageregular", serif;
    color: color("mainDark");
    font-size: 2rem;
    line-height: 95%;
    font-weight: 400;
    max-width: 65%;
    margin-bottom: 0px;
    margin-left: 115px;
    opacity: 0;
    margin-top: -5px;
  }

  .common_history_next_text_down {
    margin-bottom: 0;
    margin-top: -5px;
    margin-left: auto;
    margin-right: 100px;
  }
}
