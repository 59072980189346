.newsletter {
  max-width: 100%;

  &--light {
    padding: 80px 40px;
    text-align: center;

    .newsletter__input {
      margin: 30px auto 10px auto;
      @media (max-width: 767px) { width: 100%; }
      @media (min-width: 768px) { width: 600px; }
    }
  }

  &--hero {
    background-color: #f7f4f4;
    display: flex;

    @media (max-width: 767px) {
      flex-direction: column-reverse;
      margin: 30px 0 50px 0;
      padding: 50px 30px 0 30px;
      text-align: center;
    }

    @media (min-width: 768px) {
      gap: 90px;
      padding: 0 40px 0 40px;
      margin: 200px auto 0 auto;
      width: 1200px;
    }

    .newsletter {
      &__content {
        max-width: 100%;
        @media (min-width: 768px) {
          padding-bottom: 80px;
        }
      }

      &__input {
        margin: 30px 0 10px 0;
        @media (max-width: 767px) { width: 100%; }
        @media (min-width: 768px) { width: 460px; }
      }
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
  }

  &__image {
    @media (max-width: 767px) { transform: translateY(125px); }
    @media (min-width: 768px) { transform: translateY(-60px); }
    &--desktop {
      @media (max-width: 767px) { display: none; }
    }
    &--mobile {
      @media (min-width: 768px) { display: none; }
    }
  }

  &__title {
    font-size: 36px;
    font-weight: 400;
    margin-bottom: 40px;
    margin-top: 40px;
  }

  &__description {
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 30px;
  }

  &__form {
    &--error {
      .newsletter {
        &__error { opacity: 1; }
        &__input { border-bottom: 1px solid #CD0000; }
      }
    }
  }

  &__input {
    appearance: none;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #a4a4a4;
    box-sizing: border-box;
    display: block;
    font-family: monarcha, serif;
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    max-width: 100%;
    padding: 5px;

    &::placeholder {
      color: rgba(0, 0, 0, 0.50);
    }
  }

  &__error {
    font-size: 13px;
    color: #CD0000;
    margin-bottom: 10px;
    opacity: 0;

    span { text-decoration: underline; }
  }

  &__submit {
    appearance: none;
    background-color: transparent;
    border: none;
    color: #2a323d;
    font-family: "made_mirageregular", serif;
    font-size: 20px;
    font-weight: 400;
    padding: 10px 0;
    position: relative;

    &:hover {
      cursor: pointer;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: #000;
      height: 1px;
      width: 80%;
    }
  }

  &__mentions {
    &.hidden {
      opacity: 0;
      transform: translateY(50%);
    }
    opacity: 1;
    transform: translateY(0);
    transition: 0.5s ease;

    margin: 50px auto 0 auto;
    max-width: 800px;

    @media (max-width: 767px) { text-align: justify; }
    @media (min-width: 768px) { text-align: left; }

    p, a {
      color: #9D9D9D;
      font-size: 12px;
    }

    a { text-decoration: underline; }
  }
}

.grecaptcha-badge {
  visibility: hidden;
}
