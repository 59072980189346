.element {
  &__wrapper {
    display: flex;
    align-items: center;
    width: 100vw;
    height: 100%;
    box-sizing: border-box;

    &--eau {
      width: 1000vw;
    }

    &--terre {
      width: 1100vw;
    }

    &--air {
      width: 1000vw;
    }

    &--lumiere {
      width: 900vw;
    }
    
    @include menuBarPadding();
  }

  &__title {
    opacity: 0;
    transform: translateY(100px);
  }

  &__subtitle {
    margin-bottom: 1.5em;
    font-size: 1rem;
    font-family: "cera_pro_regular", serif;
    font-weight: normal;
    line-height: 1.25;
    letter-spacing: 0.4em;
    text-transform: uppercase;
    color: color(blueLight);
    &:after {
      content: "";
      display: block;
      margin-top: 3em;
      width: 80px;
      border-bottom: 1px solid;
    }
  }
  &__desc {
    opacity: 0;
    transform: translateY(100px);
  }
  &__img {
    opacity: 0;
    transform: translateY(100px);
  }

  @media (max-width: 1400px) {
    &__subtitle {
      margin-bottom: 1em;
      font-size: 1rem;
      &:after {
        margin-top: 2em;
      }
    }
  }

  // MOBILE
  @media (max-width: 680px) {
    &__wrapper {
      p {
        font-size: 0.875rem;
        line-height: 1.6;
      }
    }
  }
}
