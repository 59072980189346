progress {
  position: relative;
  width: 80%;
  height: 1px;
  border: none;
  appearance: none;
  -webkit-appearance: none;
  background-color: rgba(color(white), 0.2);
}
progress::-webkit-progress-bar {
  background-color: rgba(color(white), 0.2);
  border-radius: 1px;
}
progress::-webkit-progress-value {
  background-color: color(white);
}
progress::-moz-progress-bar {
  background-color: color(white);
  border-radius: 1px;
}

@media (max-width: 600px) {
  progress {
    width: 100%;
  }
  
}