.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: color(waterDark);
  z-index: 10;

  &__sound_button {
    position: absolute;
    top: 18px;
    right: 7vw;
    z-index: 3;
    width: 41px;
  }

  &__intro,
  &__map {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: inherit;
  }

  &__intro {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    .home & {
      progress {
        display: none;
      }
    }

    .element &,
    .common &,
    .history &,
    .wine &,
    .pro &,
    .confirmation &,
    .error &,
    .legals & {
      flex-direction: column;
      progress {
        margin-top: 2rem;
        width: 160px;
      }
    }
  }

  &__map {
    display: none;

    .home & {
      display: block;
    }
  }

  &__meter {
    position: absolute;
    top: 150px;
    left: 160px;
    z-index: 2;
  }

  &__counters {
    color: white;
    height: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__counter {
    letter-spacing: 0.125;
    line-height: 1;
    position: relative;
    display: flex;
    overflow: hidden;
    align-items: center;
    height: 1.625rem;
    font-size: 1.125rem;
    font-weight: 400;

    $d: 0;
    $inc: 0.375;

    & > .parent-increment {
      z-index: 1;
      display: flex;
      flex-direction: column;
      height: 100%;
      line-height: 1;
      margin-top: 10px;
      transform: translateY(#{$d}px);
    }

    @keyframes slidein {
      0% {
        transform: translateY(100%);
      }
      25% {
        transform: translateY(0%);
      }
      50% {
        transform: translateY(-100%);
      }
      75% {
        transform: translateY(-200%);
      }
      100% {
        transform: translateY(-300%);
      }
    }

    @keyframes slideinfinal {
      0% {
        transform: translateY(100%);
      }
      25% {
        transform: translateY(0%);
      }
      50% {
        transform: translateY(-100%);
      }
      75% {
        transform: translateY(-200%);
      }
      100% {
        transform: translateY(-400%);
      }
    }

    @for $i from 1 through 20 {
      & > span:nth-child(#{$i}) {
        animation: 5s ease 100ms * ($i - 1) slidein infinite;
        animation-play-state: running;

        span {
          flex: 0 0 100%;
          height: 100%;
        }

        $d: $d + $inc;
      }

      & > .activeSpan:nth-child(#{$i}) {
        animation: 5s ease 100ms * ($i - 1) slideinfinal;
        animation-play-state: running;
        -webkit-animation-fill-mode: forwards;
      }
    }
  }
}

.latlong {
  display: inline-block;
  width: 1rem;
}

@media (max-width: 600px) {
  .loader {
    &__meter {
      top: 30%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0.75);
    }
  }
}
