.wrapperMentionsLegales {
  height: 100vh;
  width: 100%;
  overflow-y: auto;

  hr{
    margin: 5em 0;
  }
  
  .MentionsLegales__content {
    margin: 0 auto;
    max-width: 1040px;
    padding: 240px 10% 120px;
    height: auto;
    
    @media (max-width: 600px) {
      padding: 140px 8% 60px;
    }
  }
  
  h1 {
    margin-bottom: 96px;
    font-size: 100px;
    line-height: 95%;
    font-weight: normal;
    
    @media (max-width: 600px) {
      font-size: 36px;
      margin-bottom: 60px;
    }
  }
  
  h2 {
    font-size: 40px;
    line-height: 140%;
    margin-top: 96px;
    margin-bottom: 24px;
    font-weight: normal;
    
    @media (max-width: 600px) {
      font-size: 24px;
      margin-top: 48px;
      margin-bottom: 24px;
    }
  }

  h3 {
    font-size: 24px;
    margin-top: 24px;
    margin-bottom: 12px;
    font-weight: normal;

    @media (max-width: 600px) {
      font-size: 20px;
      margin-top: 12px;
      margin-bottom: 12px;
    }
  }
  
  p, li {
    font-size: 16px;
    line-height: 200%;
    font-family: monarcha, serif;
    color: color(blackLight);
    margin-bottom: 1em;
  }

  a {
    color: color(blackLight);
    text-decoration: underline;
  }

  ul {
    list-style: inside;
  }

}
