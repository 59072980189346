.wrapp--earth-history {
  @include menuBarPadding();
}

.earth_history_image1 {
  object-fit: cover;
  height: 1200px;
  width: 100%;
  position: absolute;
  top: 15%;
  left: 0;
  background-position: 50%;
  background-size: cover;
}

.earth_history_image1__container {
  margin-left: auto;
  margin-right: auto;
  width: 40vw;
  margin-top: 150px;
  height: 840px;
  margin-bottom: 120px;
  object-fit: cover;
  overflow: hidden;
  position: relative;
}

@media (max-width: 1200px) and (mid-width: 601px) {
  .earth_history_image1 {
    height: 1000px;
    width: 50vw;
  }
}

@media (max-width: 600px) {
  .earth_history_image1 {
    height: 900px;
  }

  .earth_history_image1__container {
    height: 640px;
    width: 80vw;
  }
}

.earth_history_group_images {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80vw;
  margin-left: 10vw;
}

.earth_history_group_images_left {
  width: calc(65% - 10px);
  margin-right: 10px;
  display: flex;
  flex-direction: column;
}

.earth_history_group_images_right {
  width: 35%;
}

.earth_history_group_images_topleft {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.earth-history-group-1 {
  width: 35%;
  margin-right: 10px;
  height: 220px;
  object-fit: cover;
  margin-left: auto;
  margin-top: auto;
}

.earth-history-group-2 {
  width: 35%;
  height: 440px;
  object-fit: cover;
}

.earth-history-group-4 {
  width: 100%;
  height: 430px;
  object-fit: cover;
}

.earth-history-group-3 {
  width: 100%;
  height: 430px;
  object-fit: cover;
  margin-bottom: 10px;
  margin-top: 320px;
}

.earth-history-group-5 {
  width: 80%;
  height: 440px;
  object-fit: cover;
}

@media (max-width: 600px) {
  .earth_history_group_images {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80vw;
    margin-left: 10vw;
    margin-bottom: 60px;
  }

  .earth_history_group_images_left {
    width: 100%;
    display: flex;
    margin-right: 0px;
    flex-direction: column;
  }

  .earth_history_group_images_right {
    width: 100%;
  }

  .earth_history_group_images_topleft {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  .earth-history-group-1 {
    width: 100%;
    height: 220px;
    object-fit: cover;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: 10px;
  }

  .earth-history-group-2 {
    width: 100%;
    height: 220px;
    object-fit: cover;
  }

  .earth-history-group-4 {
    width: 100%;
    height: 220px;
    object-fit: cover;
    margin-bottom: 10px;
  }

  .earth-history-group-3 {
    width: 100%;
    height: 220px;
    object-fit: cover;
    margin-top: 0px;
    margin-bottom: 10px;
  }

  .earth-history-group-5 {
    width: 100%;
    height: 220px;
    object-fit: cover;
  }
}

.earth-history__grid-imgs{
  display: block;
  max-width: 80vw;
  margin: 0 auto;
}

// .earth_history_group_images{
.earth-history__grid-imgs{
  & + .history__content-title{
    margin-top: -50px;

    @media(max-width:600px){
      margin-top: 10px;
    }
  }
}

.earth_history_image2__container {
  width: 80vw;
  margin-left: 10vw;
  height: 680px;
  margin-top: 120px;
  margin-bottom: 60px;
  object-fit: cover;
  overflow: hidden;
  position: relative;
}

.earth_history_image2 {
  position: absolute;
  top: 5%;
  left: 0;
  width: 100%;
  // height: 1000px;
  min-height: 110%;
  object-fit: cover;
}

@media (max-width: 1200px) {
  .earth_history_image1 {
    height: 900px;
  }

  .earth_history_image2__container {
    height: 600px;
    width: 80vw;
  }
}

@media (max-width: 600px) {
  .earth_history_image1 {
    height: 800px;
  }

  .earth_history_image2__container {
    height: 500px;
    width: 80vw;
  }
}

// .earth_history_text_group {
//   display: flex;
//   flex-direction: row;
//   align-items: flex-start;
//   justify-content: space-between;
//   width: 80vw;
//   margin-left: 10vw;

//   > p {
//     max-width: 40%;
//   }
// }