.wrapperConfirmation {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-bottom: 30px;
  width: 100%;
  height: 100%;

  @include menuBarPadding();
}

.confirmation {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;

  &--error {
    justify-content: center;
    height: 100vh;
    .confirmation {
      &__images { display: none; }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;

    @media (max-width: 767px) { padding: 0 30px;}
    @media (min-width: 768px) {}
  }

  &__images {
    display: flex;
    gap: 1rem;
    justify-content: space-between;

    @media (min-width: 768px) { margin-bottom: 80px; }
  }

  &__picture {
    @media (max-width: 767px) {
      margin: 0 auto;
      &--1, &--2 {
        display: none;
      }
    }
  }

  &__text {
    text-align: center;

    @media (max-width: 767px) {}
    @media (min-width: 768px) { margin-bottom: 20px; }
  }

  &__message {
    margin: 0 auto;
    max-width: 800px;
    text-align: center;

    span {
      display: block;
      font-family: "made_mirageregular", serif;
      line-height: normal;
      margin-bottom: 20px;

      @media (max-width: 767px) { font-size: 28px; }
      @media (min-width: 768px) { font-size: 36px; }
    }

    &.hidden {
      display: none;
    }
  }

  &__redirect p {
    font-family: "made_mirageregular", serif;
    text-align: center;
    line-height: normal;

    @media (max-width: 767px) { font-size: 16px; }
    @media (min-width: 768px) { font-size: 20px; }
  }
}