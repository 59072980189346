.wine{
    &__type-img-container{
        position: relative;
        // width: 35vw;
        width: 560px;
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
        overflow: hidden;

        &:before{
            content: '';
            float: left;
            padding-top: 130%;
        }

        @media (max-width: 600px) {
            &--main{
                margin-right: 0;
            }
        }
    }

    &__type-img{
        position: absolute;
        top: 0;
        left: -5%;
        width: 110%;
        height: auto;
        min-height: 110%;
        object-fit: cover;
        object-position: 0 80%;
    }

    &__type-text{
        width: 560px;
        max-width: 80%;
        margin: 40px auto 0;
        line-height: 100%;
        font-family: "made_mirageregular", serif;
        font-size: 30px;
        font-weight: 600;
        color: color(blackLight);
    }

    &__columns{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 1200px;
        max-width: 80%;
        margin: 20vh auto;

        @media (max-width: 1024px) {
            margin-top: 15vh;
            margin-bottom: 15vh;
        }

        @media (max-width: 780px) {
            flex-direction: column;
        }
    }

    &__column{
        position: relative;
        width: 50%;
        margin: 0 20px;
        overflow: hidden;

        &:before{
            content: '';
            float: left;
            padding-top: 120%;
        }

        @media (max-width: 1024px) and (min-width: 781px){
            &:before{
                padding-top: 150%;
            }
        }

        @media (max-width: 780px) {
            width: 100%;
            margin-left: 0;
            margin-right: 0;

            &:first-of-type{
                margin-bottom: 40px;
            }
        }
    }

    &__column-img{
        position: absolute;
        top: 5%;
        left: 0;
        width: 100%;
        height: 100%;
        min-height: 125%;
        object-fit: cover;
        object-position: 50% 50%;

        @media (max-width: 1024px) and (min-width: 781px){
            top: 20%;
        }
    }
} 