@font-face {
  font-family: "made_mirageregular";
  src: url("../../static/fonts/made_mirage_regular.woff2") format("woff2"),
    url("../../static/fonts/made_mirage_regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing : antialiased;
}

html,
body,
main {
  position: fixed;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: "made_mirageregular", serif;
  color: color(blackLight);
  //background-color: #15283B;
}

.container {
  &--home {
    font-family: "made_mirageregular", serif;
    background-color: color(waterDark);
  }

  &--element {
  }
}

a {
  text-decoration: none;
}

p {
  font-family: monarcha, serif;
  font-weight: 300;
  font-style: normal;
  font-size: 1.125rem;
  line-height: 2;
  color: color(blackLight);
}

@media (min-aspect-ratio: 8 / 5){
  p {
    font-size: 1rem;
  }
}

@media (max-width: 1200px) {
  p {
    font-size: 1rem;
  }
}
