.slide {
  &__title {
    position: absolute;
    top: 50%;
    left: 5vw;
    transform: translate(0%, -50%);
    pointer-events: none;
  }

  &__title-underline {
    text-decoration: underline;
    text-decoration-thickness: 3px;
    text-underline-offset: 8px;

    &::last-letter {
      text-decoration: none;
    }
  }

  &__title-text {
    font-size: 5rem;
    line-height: 1.1;
    color: var(--color-title);
    pointer-events: none;
  }

  &__poetry {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    pointer-events: none;
  }

  &__poetry-text {
    font-size: 2.75rem;
    text-align: center;
    line-height: 1.6;
    color: var(--color-title);
    pointer-events: none;
  }

  &__button {
    position: absolute;
    left: 50%;
    bottom: 30px;
    padding: 10px;
    font-size: 1.5rem;
    line-height: 1.3;
    text-align: center;
    color: color(white);
    opacity: 0;
    border: 1px solid rgba(255,255,255,0.4);
    box-sizing: border-box;
    pointer-events: none;
    z-index: 10;
    transform: translateX(-50%);
    transition: all 0.6s ease-out 0s;

    &--active {
      opacity: 1;
      pointer-events: auto;
    }

    &:hover{
      border-color: white;
    }
  }

  @media (max-width: 1200px) and (min-width: 601px) {
    &__title {
      top: calc(80px + 2rem);
      transform: translate(0%, 0%);
    }

    &__title-text {
      font-size: 4rem;
    }

    &__poetry {
      width: 70%;
    }

    &__poetry-text {
      font-size: 2.745rem;
      line-height: 1.4;
    }

    &__button {
      bottom: 5rem;
      font-size: 1.5rem;
      bottom: 0;
    }
  }

  @media (max-width: 600px) {
    &__title {
      top: 60px;
      left: 0vw;
      padding: 1rem 1.5rem 1.5rem;
      transform: translate(0%, 0%);
    }

    &__title-text {
      font-size: 2.875rem;
      line-height: 1.1;
    }

    &__poetry {
      width: 90%;
    }

    &__poetry-text {
      font-size: 1.5rem;
      line-height: 1.3;
    }

    &__button {
      width: max-content;
      padding: 6px;
      font-size: 1.25rem;
    }
  }
}
