.AgeCheck__container {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  position: relative;
  z-index: 20;
  background-color: color(waterDark);
  display: flex;
  justify-content: center;
  align-items: center;
}

.AgeCheck__modal {
  color: white;
  padding: 6%;
  text-align: center;
  
  img {
    margin-bottom: 32px;
  }
}

.AgeCheck__question {
  font-size: 18px;
  margin-bottom: 32px;
}

.AgeCheck__answers {
  button {
    box-sizing: border-box;
    height: 37px;
    background-color: color(waterDark);
    color: white;
    border: none;
    text-transform: capitalize;
    font-size: 18px;
    font-family: "made_mirageregular", serif;
    cursor: pointer;
    padding: 10px 18px;
    outline: none;
    
    &:first-child {
      margin-right: 24px;
    }
    
    &:hover {
      span {
        border-bottom: 1px solid white;
      }
    }
  }
}
