@function colors() {
  $colors: (
    white: #fff,
    offWhite: #f7f4f4,
    black: #000,
    blackLight: #2a323d,
    blueLight: #B2C7E4,
    waterLight: #fff,
    waterDark: #15283b,
    earthLight: #fff,
    earthDark: #233b3d,
    airLight: #fff,
    airDark: #333d4a,
    lightLight: #fff,
    lightDark: #1c2732,
    mainDark: #2a323d,
    grey: #e6eaec,
  );

  @return $colors;
}

@function color($name) {
  @return map-get(colors(), $name);
}
