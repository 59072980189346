.error {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    padding-bottom: 30px;
    width: 100%;
    height: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;

    @media (max-width: 767px) { padding: 0 30px;}
    @media (min-width: 768px) {}
  }

  &__text {
    text-align: center;

    @media (max-width: 767px) {}
    @media (min-width: 768px) { margin-bottom: 20px; }
  }

  &__message {
    margin: 0 auto;
    max-width: 800px;
    text-align: center;

    span {
      display: block;
      font-family: "made_mirageregular", serif;
      line-height: normal;

      @media (max-width: 767px) { font-size: 28px; }
      @media (min-width: 768px) { font-size: 36px; }
    }
  }

  &__redirect p {
    font-family: "made_mirageregular", serif;
    text-align: center;
    line-height: normal;

    a {
      color: inherit;
      text-decoration: underline;
    }

    @media (max-width: 767px) { font-size: 24px; }
    @media (min-width: 768px) { font-size: 30px; }
  }
}

