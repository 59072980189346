.wrapperRoseWine {
  display: flex;
  flex-direction: column;

  @include menuBarPadding();
}

.roseWine__intro__strap {
  margin-left: calc(20vw + 20px);
  margin-top: 12vh;
  text-transform: uppercase;
  font-family: "made_mirageregular", serif;
  color: #2a323d;
  font-size: 18px;
  font-weight: 500;
  line-height: 200%;
}

.roseWine__intro__title {
  margin-left: 20vw;
  margin-bottom: 15vh;
  font-family: "made_mirageregular", serif;
  color: #2a323d;
  font-size: 60px;
  font-weight: 400;
  line-height: 95%;
}

@media (max-width: 1200px) and (min-width: 601px) {
  .roseWine__intro__title {
    margin-bottom: 5vh;
  }
}

@media (max-width: 600px) {
  .roseWine__intro__strap {
    margin-left: calc(5vw + 20px);
  }

  .roseWine__intro__title {
    margin-bottom: 3vh;
    margin-left: calc(5vw);
  }
}

.roseWine__main__img {
  object-fit: cover;
  height: 1100px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-position: 50%;
  background-size: cover;
}

.roseWine__main__img_container {
  margin-left: auto;
  margin-right: auto;
  width: 35vw;
  height: 715px;
  object-fit: cover;
  overflow: hidden;
  position: relative;
}

@media (max-width: 1400px) and (min-width: 600px) {
  .roseWine__main__img_container {
    width: 50vw;
  }
}

@media (max-width: 600px) {
  .roseWine__main__img {
    height: 750px;
  }

  .roseWine__main__img_container {
    height: 500px;
  }
}

.roseWine__main__content {
  margin-top: -160px;
  margin-left: 18vw;
}

.roseWine__main__label {
  opacity: 0.3;
  text-transform: uppercase;
  font-family: "made_mirageregular", serif;
  color: #2a323d;
  font-size: 18px;
  font-weight: 500;
  line-height: 200%;
}

.roseWine__main__vintage {
  margin-top: 0.25em;
  font-family: "made_mirageregular", serif;
  color: #2a323d;
  font-size: 220px;
  font-weight: 400;
  line-height: 95%;
}

@media (max-width: 1200px) and (min-width: 601px) {
  .roseWine__main__content {
    margin-top: -110px;
    margin-left: 5vw;
  }

  .roseWine__main__vintage {
    font-size: 140px;
  }
}

@media (max-width: 600px) {
  .roseWine__main__img_container {
    margin-left: auto;
    margin-right: 0;
    width: 80vw;
    height: 500px;
    object-fit: cover;
  }

  .roseWine__main__content {
    margin-top: -90px;
    margin-left: 5vw;
    display: flex;
    flex-direction: column-reverse;
  }

  .roseWine__main__vintage {
    font-size: 90px;
  }
}

.roseWine__presentation__title {
  max-width: 25vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5vh;
  font-family: "made_mirageregular", serif;
  color: #2a323d;
  font-size: 30px;
  line-height: 100%;
}

.roseWine__presentation__text {
  max-width: 25vw;
  margin-top: 5vh;
  margin-left: auto;
  margin-right: auto;
  line-height: 200%;
  font-size: 18px;
  font-weight: 300;
  font-family: monarcha, serif;
  
}

@media (max-width: 1400px) and (min-width: 601px) {
  .roseWine__presentation__title {
    max-width: 50vw;
  }

  .roseWine__presentation__text {
    max-width: 50vw;
    margin-left: 25vw;
  }
}

@media (max-width: 600px) {
  .roseWine__presentation__title {
    max-width: 80vw;
    margin-left: 10vw;
    font-size: 24px;
  }

  .roseWine__presentation__text {
    max-width: 80vw;
    font-size: 16px;
  }
}

.roseWine__variety {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 20vh;
}

.roseWine__variety__value_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
}

.roseWine__variety__label {
  font-family: "made_mirageregular", serif;
  color: #2a323d;
  font-size: 30px;
  font-weight: 500;
  line-height: 100%;
  margin-top: -3px;
}

.roseWine__variety__separator {
  margin-left: 20px;
  margin-right: 20px;
  width: 20px;
  height: 1px;
  background-color: #002130;
  opacity: 0.1;
}

.roseWine__variety__value {
  font-style: italic;
  font-family: monarcha, serif;
  line-height: 115%;
  font-weight: 300;
  font-size: 24px;
  color: #2a323d;
  transform: translate(0, -120);
}

@media (max-width: 600px) {
  .roseWine__variety {
    flex-direction: column;
  }

  .roseWine__variety__label {
    margin-bottom: 20px;
  }
}

.roseWine__download {
  margin-top: 30vh;
  height: 620px;
  margin-left: 10vw;
  width: 80vw;
  position: relative;
  background-color: #f7f4f4;
}

.roseWine__download__img {
  object-fit: cover;
  height: 800px;
  width: 100%;
  position: absolute;
  top: 20%;
  left: 0;
  background-position: 50%;
  background-size: cover;
}

.roseWine__download__img_container {
  position: absolute;
  top: -40px;
  left: 40px;
  height: 580px;
  width: 30vw;
  overflow: hidden;
}

.roseWine__download__content {
  margin-left: 50%;
  margin-top: 8%;
}

.roseWine__download__text {
  max-width: 70%;
  font-family: "made_mirageregular", serif;
  color: #2a323d;
  font-size: 40px;
  font-weight: 500;
  line-height: 130%;
  margin-bottom: 50px;
}

.roseWine__download__link {
  font-family: "made_mirageregular", serif;
  color: #2a323d;
  font-size: 24px;
  font-weight: 400;
  line-height: 100%;
}

.roseWine__download__line {
  width: 260px;
  height: 1px;
  background-color: #2a323d;
  margin-top: 10px;
}

@media (max-width: 1200px) and (min-width: 601px) {
  .roseWine__download {
    margin-top: 30vh;
    height: 520px;
    margin-left: 5vw;
    width: 90vw;
    position: relative;
    background-color: #f7f4f4;
  }

  .roseWine__download__img {
    height: 650px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: 50%;
    background-size: cover;
  }

  .roseWine__download__img_container {
    position: absolute;
    top: -40px;
    left: 40px;
    height: 480px;
    width: 30vw;
    overflow: hidden;
  }

  .roseWine__download__content {
    margin-left: 50%;
    margin-top: 8%;
  }

  .roseWine__download__text {
    max-width: 70%;
    font-family: "made_mirageregular", serif;
    color: #2a323d;
    font-size: 30px;
    font-weight: 500;
    line-height: 130%;
    margin-bottom: 50px;
  }

  .roseWine__download__link {
    font-family: "made_mirageregular", serif;
    color: #2a323d;
    font-size: 18px;
    font-weight: 500;
    line-height: 100%;
  }

  .roseWine__download__line {
    width: 260px;
    height: 1px;
    background-color: #2a323d;
    margin-top: 10px;
  }
}

@media (max-width: 600px) {
  .roseWine__download {
    margin-top: 20vh;
    height: 720px;
    margin-left: 0;
    width: 100vw;
    position: relative;
    background-color: #f7f4f4;
  }

  .roseWine__download__img {
    height: 800px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: 50%;
    background-size: cover;
  }

  .roseWine__download__img_container {
    position: relative;
    top: -40px;
    left: 0px;
    height: 500px;
    width: 80vw;
    overflow: hidden;
  }

  .roseWine__download__content {
    margin-left: 10%;
    margin-top: -30px;
  }

  .roseWine__download__text {
    max-width: 80%;
    font-family: "made_mirageregular", serif;
    color: #2a323d;
    font-size: 24px;
    font-weight: 500;
    line-height: 130%;
    margin-bottom: 50px;
  }

  .roseWine__download__link {
    font-family: "made_mirageregular", serif;
    color: #2a323d;
    font-size: 18px;
    font-weight: 500;
    line-height: 100%;
  }

  .roseWine__download__line {
    width: 260px;
    height: 1px;
    background-color: #2a323d;
    margin-top: 10px;
  }
}
