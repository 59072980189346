.wrapperEspacePro {
  height: 100%;
  overflow: auto;

  .EspacePro__background {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 45vh;
    z-index: -1;
  }

  .EspacePro__content {
    margin-top: 162px;

    @media (min-width: 768px) and (max-width: 1200px) {
      margin-top: 128px;
    }

    @media (max-width: 768px) {
      margin-top: 86px;
      display: flex;
      flex-direction: column;
      max-height: calc(100% - 100px);
    }
  }

  h1 {
    margin: 0 auto 120px;
    max-width: 440px;
    font-size: 18px;
    line-height: 200%;
    text-align: center;
    color: color(blackLight);
    font-family: monarcha, serif;
    font-weight: 300;

    @media (max-width: 768px) {
      flex-grow: 0;
      flex-basis: 20%;
      max-width: 265px;
      margin-bottom: 20px;
    }
  }

  .EspacePro__images-list {
    flex: 70% 0 1;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: stretch;
    padding: 0 20px;

    @media (max-width: 768px) {
      flex-basis: 80%;
      overflow-y: auto;
      flex-direction: row;
      justify-content: flex-start;
      margin: 0 8%;
      padding: 0;
    }
  }

  a.EspacePro__image-card {
    max-width: 280px;
    margin-right: 60px;

    min-height: 510px;

    @media (min-width: 768px) and (max-width: 1200px) {
      max-width: 200px;
      margin-right: 42px;
    }

    @media (max-width: 768px) {
      flex: 1 0 80%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 100%;
      margin-right: 5%;
      padding-bottom: 10px; // so that scroll bar doesn't overlap text
    }

    &:last-child {
      margin-right: 0;
    }

    img {
      margin-bottom: 20px;
      width: 100%;
      @media (max-width: 768px) and (orientation: portrait) {
        margin-bottom: 12px;
        width: 100%;
        height: auto;
      }
    }

    h2 {
      font-size: 18px;
      color: color(blackLight);
      font-weight: 300;
      margin-bottom: 0;
    }

    .EspacePro__image-link {
      font-size: 18px;
      color: color(blackLight);
      font-weight: 300;
      padding-top: 12px;
      text-decoration: underline;
      display: inline-block;
      overflow: hidden;
      max-height: 0;
      transition: max-height 0.25s ease-out;

      @media (max-width: 768px) {
        display: none;
      }
    }

    &:hover .EspacePro__image-link {
      max-height: 35px;
      transition: max-height 0.15s ease-in;
    }
  }
}
